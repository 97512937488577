<template>
  <div class="modal">
    <div style="flex-direction: column;">
      <h1>Register</h1>
      <form>
        <label for="fname">First name:</label><br>
        <input type="text" id="fname" name="fname" style="padding: 10px;"><br>
        <label for="lname">Last name:</label><br>
        <input type="text" id="lname" name="lname" style="padding: 10px;"><br>
        <label for="email">Email:</label><br>
        <input type="text" id="email" name="lname" style="padding: 10px;"><br>
        <label for="contact_number">Contact Number:</label><br>
        <input type="text" id="contact_number" name="lname" style="padding: 10px;"><br>
        <button style="padding: 10px; margin-top: 10px;">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterForm",

}
</script>

<style>
.modal {
  display: flex;
  justify-content: center;
  z-index: 50;
  padding: 3rem;
  border-radius: 25px;
  position: absolute; 
  left: 0;
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  min-width: 240px;
  max-width: 480px; 
  margin-bottom: 5rem;
  top: 10rem; 
  border: 1px solid lightblue; 
  background: #fff
}
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
