<template>
  <LandingPage msg="Welcome to Your Vue.js App"/>
</template>

<script>
import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
@font-face {
  font-family: "Poppins-ExtraLight";
  src: local("Poppins"),
   url(./fonts/Poppins-ExtraLight.ttf) format("truetype");
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
