<template>
  <div class="hello" style="font-family: Poppins-ExtraLight;">
    <div style="display: flex; flex-direction: row; padding: 10px; justify-content: space-between;">
      <p style="width: 90px;">&nbsp;</p>
      <h1>Crediton Wholesale</h1>
      <p style="margin-top: 25px; border: 1px solid black; border-radius: 15px; padding: 10px;" @click="showModal()">Register</p>
    </div>
    <div style="border-top: 1px solid gray; display: flex; justify-content: space-around;">
      <p class="txt">Service</p>
      <p class="txt">Quality</p>
      <p class="txt">Integrity</p>
    </div>
    <div class="backgroundy" style="height: 55rem; justify-content: end;">
      <div style="display: flex; flex-direction: column; color: white; text-align: left; padding: 20px; background: black; height: 20rem; opacity: 0.8;">
        <h4 style="font-size: 24px; font-weight: 600;">About</h4>
        <p>We are an established and experienced wholesaler in the heart of Devon.</p>
        <p>With a wide variety of products, we supply to many independent and local businesses throughout the country.</p>
        <p>For a comprehensive list of all our products and prices, please use the registation form at the top of this page.</p>
      </div>
    </div>
    <RegisterForm v-if="showRegisterModal" />
    <CategoryList />

  </div>
</template>

<script>
import RegisterForm from './RegisterForm.vue';
import CategoryList from './CategoryList.vue';

export default {
  name: 'LandingPage',
  components: {
    RegisterForm,
    CategoryList
  },
  props: {
    msg: String
  },
  data() {
    return {
      showRegisterModal: false
    }
  },
  created() {
    document.title = "Crediton Wholesale"
  }, 
  methods: {
    showModal() {
      this.showRegisterModal = !this.showRegisterModal
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.txt:hover {
    text-decoration: underline;
    cursor: pointer;
}
.backgroundy {
  background-image: url('@/assets/store-g119c5ed58_1920.jpg');
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
