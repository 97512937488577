<template>
  <div v-for="category in categories" v-bind:key="category.name" style="display: inline-grid; margin-left: auto; margin-right: auto;">
    <div style="display: flex; flex-direction: column;">
      <h4 style="font-size: 30px;">{{ category.name }}</h4>
      <img :src="getImage(category.image)" style="width: 28rem; height: 20rem;" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryList",
  data() {
    return {
      categories: [
        {
          name: "Baby Supplies",
          image: "siblings-g124db160d_640.jpg"
        },
        {
          name: "Toys",
          image: "child-gbb30fcf08_640.jpg"
        },
        {
          name: "Health & Beauty",
          image: "dumbbells-g0c90acfe4_640.jpg"
        },
        {
          name: "Homeware",
          image: "ironing-g8ab341d2d_640.jpg"
        },
        {
          name: "Leisure & Hobbies",
          image: "yoga-g3557cf1cf_640.jpg"
        },
        {
          name: "Party & Gifts",
          image: "happy-birthday-gfda79a2c9_640.jpg"
        },
        {
          name: "Tech",
          image: "virtual-g829376ed6_640.jpg"
        },
        {
          name: "Seasonal",
          image: "bag-g0877dddbb_640.jpg"
        },
      ]   
    }
  },
  methods: {
    getImage(file) {
      return require(`../assets/${file}`)
    }
  }
}
</script>